import React from "react";
import logo from "../images/logo.png"; // Update with your logo path
// import { useNavigate } from 'react-router-dom';
// import heroImage from './images/hero-image.png'; // Optional: Update with your hero image path if you have one

const App = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Header />
      <HeroSection />
      <DownloadSection />
      <Footer />
    </div>
  );
};

const Header = () => {
  // const navigate = useNavigate()
  return (
    <header className="bg-black p-4 md:p-6 flex justify-between items-center fixed top-0 w-full z-10 shadow-lg">
      <div className="flex items-center space-x-3">
        <img src={logo} alt="Logo" className="h-[10vh] object-fit" />{" "}
        {/* Logo */}
      </div>
      <nav className="space-x-6">
        {/* <a onClick={()=> navigate('/login')} className="text-gray-300 hover:text-white font-medium">Login</a> */}
      </nav>
    </header>
  );
};

const HeroSection = () => (
  <section
    className="relative text-center py-20 md:py-32 bg-cover bg-center"
    style={{ backgroundImage: `` }}
  >
    <div className="bg-black bg-opacity-70 inset-0 absolute"></div>{" "}
    {/* Dark overlay */}
    <div className="relative z-10 container mx-auto px-6">
      <h2 className="text-4xl md:text-5xl font-bold leading-snug">
        Grow Your Wealth with Confidence
      </h2>
      <p className="mt-4 text-lg md:text-xl max-w-2xl mx-auto">
        Download our app and start investing in profitable plans to secure your
        financial future with ease.
      </p>
      <div className="mt-8">
        {/* <a href="https://drive.google.com/uc?id=1jsmBg8JYUGq-K0HwQaKgrTa8xx7piKtu&export=download" className="bg-green-500 text-white hover:bg-green-600 px-6 py-3 rounded-full font-semibold shadow-lg transition duration-200">
          Download
        </a> */}
        <a
          href="https://github.com/sourabh07032000/SarteAssetsMining-App/releases/download/v1.0.1/app-release.apk"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-green-500 text-white hover:bg-green-600 px-6 py-3 rounded-full font-semibold shadow-lg transition duration-200"
        >
          Download
        </a>
      </div>
    </div>
  </section>
);

const DownloadSection = () => (
  <section id="download" className="py-20 bg-gray-900 text-center px-6">
    <div className="container mx-auto">
      <h2 className="text-3xl md:text-4xl font-bold text-white">
        Download the Official App
      </h2>
      <p className="text-gray-400 mt-4">
        Enter your mobile number to receive the download link.
      </p>
      <div className="mt-8 flex justify-center items-center space-x-4">
        <div className="bg-white flex rounded-full overflow-hidden shadow-md">
          <span className="bg-gray-200 px-3 py-2 text-gray-700">+91</span>
          <input
            type="text"
            placeholder="Enter Mobile Number"
            className="p-2 w-40 md:w-64 outline-none"
          />
        </div>
        <button
          href="https://github.com/sourabh07032000/SarteAssetsMining-App/releases/download/v1.0.1/app-release.apk"
          className="bg-green-500 hover:bg-green-600 text-white font-semibold px-6 py-2 rounded-full shadow-lg transition duration-200"
        >
          Get App Link
        </button>
        {/* <button
          href="https://drive.google.com/uc?id=1jsmBg8JYUGq-K0HwQaKgrTa8xx7piKtu&export=download"
          className="bg-green-500 hover:bg-green-600 text-white font-semibold px-6 py-2 rounded-full shadow-lg transition duration-200"
        >
          Get App Link
        </button> */}
      </div>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-gray-800 text-gray-400 p-6 md:p-8 text-center">
    <p>&copy; 2024 Sarte Assets Mining App. All rights reserved.</p>
    <div className="mt-4 space-x-4">
      <label className="hover:text-white">Privacy Policy</label>
      <label className="hover:text-white">Terms & Conditions</label>
    </div>
  </footer>
);

export default App;
